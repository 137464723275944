<template>
  <div class="w-100" style="position:absolute">
    <TransitionGroup name="fade-absolute">
      <div v-if="data.note" class="journal-note" style="padding: 28px 40px;">
        <AppText color="var(--color-black-04)" variant="div" mb="20px">
          <i18n-t keypath="merchantPayments.paymentInfoModal.journal.paidManually">
            <template #date>
              <DateFormatter :date="data.updatedAt" />
            </template>
            <template #time>
              <DateFormatter :date="data.updatedAt" :format="timeFormat" />
            </template>
          </i18n-t>
        </AppText>
        <AppText variant="div" class="note">
          {{ data.note }}
        </AppText>
      </div>

      <div v-else style="padding: 28px 40px;">
        <div style="margin-bottom: 30px;">
          <AppText
            color="var(--color-black-06)"
            variant="div"
            mb="30px"
            :line-height="1.5"
          >
            {{ t('merchantPayments.paymentInfoModal.journal.text1') }}
          </AppText>
          <AppText color="var(--color-black-06)" :line-height="1.5" class="font-medium">
            {{ t('merchantPayments.paymentInfoModal.journal.text2') }}
          </AppText>
        </div>
        <ActionForm
          :request-fields="requestFields"
          :validation-schema="validationSchema"
          :response-errors="fieldsErrors"
          class="form"
          @input="onFieldsInput"
          @validate="onJournalPayment"
          @error="onValidationError"
        >
          <template #default="{ errors, action }">
            <FInput
              v-model="requestFields.note"
              :validation-error="errors.note"
              class="memo"
              type="textarea"
              maxlength="300"
              show-word-limit
              mask="all"
              :placeholder="t('merchantPayments.paymentInfoModal.journal.memoPlaceholder')"
            />
            <FSpacer style="margin-bottom: 40px;" />

            <FSlider
              ref="slide"
              :error="hasError"
              :title="t('merchantPayments.paymentInfoModal.journal.title')"
              :subtitle="t('merchantPayments.paymentInfoModal.journal.subtitle')"
              @complete="action"
            />
          </template>
        </ActionForm>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { ActionForm, fieldValidation, memoRule } from '@/validation';
import { useProfile } from '@/composables/useProfile';

import { journalPayment } from '../api';

export default defineComponent({
  name: 'PaymentJournal',
  components: {
    ActionForm,
    DateFormatter,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['complete'],
  setup(props, { emit }) {
    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
    } = fieldValidation({
      fieldName: 'note',
      rule: memoRule({ min: 20, max: 300 }),
    });

    const journalMemo = ref('');

    const slide = ref(null);

    const onJournalPayment = async () => {
      const { isSuccess } = await journalPayment(props.id, {
        note: requestFields.note,
      });
      if (isSuccess) {
        await slide.value.complete();
        emit('complete');
      } else {
        slide.value.reset();
      }
    };

    const hasError = ref(false);
    const onValidationError = () => {
      hasError.value = true;
      setTimeout(() => { hasError.value = false; }, 0);
    };
    const onFieldsInput = () => { hasError.value = false; };

    const { timeFormat } = useProfile();

    return {
      hasError,

      journalMemo,
      onJournalPayment,
      onValidationError,
      onFieldsInput,

      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
      timeFormat,

      slide,
    };
  },
});
</script>

<style scoped lang="scss">
.journal-note {
  background: #F7F7F7;
}
.note {
  padding-left: 20px;
  border-left: 3px solid var(--color-black-005);
}
.memo {
  :deep(.el-textarea__inner) {
    min-height: 120px !important;
  }
}
</style>
