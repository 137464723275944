<template>
  <div class="h-100 d-flex flex-column justify-content-between" :class="{ 'align-items-center': isCentered }">
    <TooltipCopy is-icon-hidden is-min-gap :text="String(text)">
      <AppText
        ref="amountRef"
        variant="div"
        :line-height="1.23"
        :size="fontSize"
        class="font-medium"
      >
        <slot />
      </AppText>
    </TooltipCopy>
    <AppText
      ref="descRef"
      variant="div"
      size="12px"
      :line-height="1.23"
      color="#949494"
    >
      <slot name="description" />
    </AppText>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';

export default defineComponent({
  components: {
    TooltipCopy,
  },
  props: {
    isCentered: {
      type: Boolean,
      default: false,
    },
    text: {
      type: [String, Number],
      default: '',
    },
  },
  setup() {
    const amountRef = ref(null);
    const descRef = ref(null);

    const fontSize = computed(() => ((amountRef.value?.$el?.innerText?.length < 13) || (descRef.value?.$el?.innerText?.length) < 13 ? '22px' : '18px'));

    return { amountRef, descRef, fontSize };
  },
});
</script>
