<template>
  <div class="d-flex align-items-center justify-content-center info">
    <AmountValue :is-centered="!Boolean(payment.exchangeCurrency)" :text="payment.baseAmount">
      <CurrencyFormatter
        :value="payment.baseAmount"
        :name="payment.baseCurrency"
        is-suffix
        size="22px"
        class="font-medium"
      />
      <template #description>
        <AppText
          v-if="payment.status === 'NEW'"
          color="#949494"
          variant="div"
          align="center"
          :line-height="1.23"
        >
          {{ t('merchantPayments.paymentInfoModal.label.notActivatedLink') }}
        </AppText>
        <AppText
          v-else-if="payment.status === 'CANCELLED' && !payment.exchangeCurrency"
          color="var(--color-error)"
          variant="div"
          align="center"
          :line-height="1.23"
        >
          {{ cancelledDescription }}
        </AppText>
        <template v-else>
          <AppText variant="div" size="12px" :line-height="1.25">
            {{ t('merchantPayments.paymentInfoModal.label.paymentAmount') }}
          </AppText>
        </template>
      </template>
    </AmountValue>

    <template v-if="payment.exchangeCurrency">
      <div class="sign-wrap">
        <AppTooltip v-if="payment.slippage?.isApplied" cursor="default">
          <template #text>
            {{ t('merchantPayments.paymentInfoModal.label.includesSlippage', { value: payment.slippage?.percentage }) }}
          </template>

          <div class="almost-equal-sign">
            ≈
          </div>
        </AppTooltip>

        <div v-else class="equal-sign">
          <span />
          <span />
        </div>
      </div>

      <AmountValue :text="paymentAmount">
        {{ paymentAmount }} {{ coinName }}
        <template #description>
          <AppText variant="div" size="12px" :line-height="1.25" color="#949494">
            1 {{ coinName }} = <CurrencyFormatter
              :value="marketRate"
              is-account-currency
              size="12px"
              :line-height="1.25"
              :type="currencyTrimmedType"
            />
          </AppText>
        </template>
      </AmountValue>

      <div v-if="selectedCurrency.icon" class="payment-icon" @click="isAltcoinsVisible = !isAltcoinsVisible">
        <AppIcon
          v-if="selectedCurrency.isPrimary"
          :key="selectedCurrency.value"
          :name="selectedCurrency.icon"
          size="40px"
        />
        <FamilyIcon
          v-else
          :icon="selectedCurrency.icon"
          :family-icon="selectedCurrency.familyIcon"
          size="40px"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import FamilyIcon from '@/components/Icon/FamilyIcon.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import { i18n } from '@/plugins/localization';
import { fullCoinsArray, numberTypes } from '@/common/data';

import AmountValue from './AmountValue.vue';

export default defineComponent({
  components: {
    CurrencyFormatter,
    AmountValue,
    AppTooltip,
    FamilyIcon,
  },
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { t } = i18n.global;

    const cancelledDescription = computed(() => (props.payment.cancellationReason === 'AUTOMATIC'
      ? t('merchantPayments.paymentInfoModal.label.cancelledAutomaticly')
      : t('merchantPayments.paymentInfoModal.label.cancelledManually')));

    const paymentCurrency = computed(() => (props.payment.choiceETH || props.payment.exchangeCurrency));
    const selectedCurrency = computed(() => fullCoinsArray.find((coin) => coin.value === paymentCurrency.value));

    const marketRate = computed(() => props.payment.marketRate);
    const paymentAmount = computed(() => props.payment.expectedAmount);

    const currencyTrimmedType = computed(() => (!selectedCurrency.value?.isPrimary ? numberTypes.FLOAT4 : numberTypes.FLOAT2));
    const coinName = computed(() => {
      if (selectedCurrency.value?.value === 'ETH_ETH') {
        return selectedCurrency.value.shortValue;
      }
      return selectedCurrency.value.isPrimary ? selectedCurrency.value.value : selectedCurrency.value.name;
    });

    return {
      cancelledDescription,

      marketRate,

      paymentAmount,
      selectedCurrency,

      coinName,
      currencyTrimmedType,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  margin-top: 30px;
  height: 50px;
}

.sign-wrap {
  margin: 0 40px;
}

.equal-sign, .almost-equal-sign {
  flex-shrink: 0;
  background: #EDEDED;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  flex-direction: column;
  gap: 4px;
  @include flex-center;
}

.equal-sign {
  > span {
    width: 10px;
    height: 2px;
    background: var(--color-black);
  }
}

.almost-equal-sign {
  background: #666666;
  font-size: 24px;
  color: var(--color-white);
  justify-content: flex-start;
}

.payment-icon {
  position: relative;
  margin-left: 48px;
}
</style>
