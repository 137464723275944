<template>
  <div>
    <div class="details">
      <div class="detail-line">
        <AppText class="detail-line-title is-first-letter-capitalized">
          {{ t('common.client', 1) }}
        </AppText>
        <div class="d-flex align-items-center detail-line-info">
          <template v-if="isIncognito">
            <AppText
              color="var(--color-black-04)"
              variant="div"
              class="d-flex align-items-center"
            >
              <AppIcon
                name="incognito"
                size="17px"
                style="margin-right: 12px;"
                opacity="0.4"
              />
              {{ t('common.incognito') }}
            </AppText>
          </template>
          <template v-else-if="isUnregistered">
            <AppText class="hover" @click="runSearch(clientInfo.email)">
              {{ truncatedEmail }}
            </AppText>

            <div class="custom-divider">
              –
            </div>

            <AppTooltip width="250px" cursor="default">
              <AppText color="var(--color-black-04)">
                {{ t('status.UNREGISTERED') }}
              </AppText>
              <template #text>
                Unregistered clients are marked registered only after their payment is completed
              </template>
            </AppTooltip>
          </template>
          <UserInfoLine v-else :client="clientInfo" @run-search="runSearch" />
        </div>
      </div>
      <div class="detail-line">
        <AppText class="detail-line-title">
          {{ t('common.type') }}
        </AppText>
        <AppText class="detail-line-info">
          {{ paymentType }}
          <AppText
            v-if="payment.type === 'SUBSCRIPTION_PAYMENT'"
            pl="10px"
            class="hover"
            opacity="0.4"
            @click="runSearch(payment.clientSubscriptionId)"
          >
            {{ payment.clientSubscriptionId }}
          </AppText>
          <AppText
            v-if="payment.type === 'API_PAYMENT'"
            pl="10px"
            color="var(--color-black-04)"
          >
            <TooltipCopy is-icon-hidden :text="payment.key">
              {{ paymentKey }}
              <AppText v-if="payment.keyNote" class="strict-color">
                &nbsp;({{ payment.keyNote }})
              </AppText>
            </TooltipCopy>
          </AppText>
        </AppText>
      </div>
      <div class="detail-line">
        <AppText class="detail-line-title">
          {{ t('merchantPayments.paymentInfoModal.label.revoke') }}
        </AppText>
        <AppText class="detail-line-info">
          <template v-if="!payment.autoRevoke">
            <AppText opacity="0.4">
              {{ t('merchantPayments.paymentInfoModal.label.notSet') }}
            </AppText>
          </template>
          <template v-else-if="payment.autoRevoke && ['CANCELLED', 'COMPLETE', 'WAITING'].includes(payment.status)">
            <AppText style="text-decoration: line-through;">
              {{ revokeText }}
            </AppText>
          </template>
          <template v-else>
            <AppText is-block mr="8px">
              <i18n-t keypath="merchantPayments.paymentInfoModal.label.revokeIn">
                <template #time>
                  <DateDurationWithNow :date="payment.autoRevokeDate" />
                </template>
              </i18n-t>
            </AppText>
          </template>
        </AppText>
      </div>
    </div>
    <div class="memo-wrapper">
      <div v-if="payment.memo && payment.type !== 'SUBSCRIPTION_PAYMENT'" class="memo">
        <AppText
          variant="div"
          :line-height="1"
          opacity="0.4"
          class="font-medium"
        >
          {{ t('common.memo') }}
        </AppText>
        <TooltipCopy is-icon-hidden is-inlined>
          <AppText :line-height="1.5" mt="12px">
            {{ payment.memo }}
          </AppText>
        </TooltipCopy>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import DateDurationWithNow from '@/components/DateDurationWithNow.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import UserInfoLine from '@/components/Payment/UserInfoLine.vue';

import { emitter } from '@/composables/useBus';
import { i18n } from '@/plugins/localization';
import { useSettings } from '@/composables/useSettings';
import { truncateLongString } from '@/utils/functions';

import { revokePeriods } from '../../data';

export default defineComponent({
  name: 'PaymentDetails',
  components: {
    AppTooltip,
    DateDurationWithNow,
    TooltipCopy,
    UserInfoLine,
  },
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
    clientInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { dateFormat } = useSettings();
    const { t } = i18n.global;

    const paymentType = computed(() => t(`paymentType.${props.payment?.type}`));

    const runSearch = (val) => {
      emitter.emit('run-search', val.toString());
      emitter.emit('close-modal');
    };

    const isIncognito = computed(() => !props.clientInfo.email && !props.clientInfo.shortId);
    const isUnregistered = computed(() => props.clientInfo.email && !props.clientInfo.name && !props.clientInfo.shortId);

    const revokeText = computed(() => revokePeriods.value.find((el) => el.value === props.payment.autoRevoke)?.text);
    const truncatedEmail = computed(() => truncateLongString(props.clientInfo?.email));
    const paymentKey = computed(() => truncateLongString(props.payment.key, {
      threshold: 5, startLength: 10, endLength: 6, dots: '....',
    }));

    return {
      dateFormat,
      revokeText,
      paymentType,
      truncatedEmail,
      runSearch,

      isIncognito,
      isUnregistered,

      paymentKey,
    };
  },
});
</script>

<style scoped lang="scss">
.details {
  padding: 20px 40px 40px 40px;
}
.detail-line {
  height: 48px;
  padding: 14px 0;
  border-bottom: 1px solid var(--color-black-01);
  display: flex;
  align-items: center;
  &-title {
    color: #666;
    display: inline-block;
    width: 120px;
  }
  &-info {
    flex: 100%;
  }
  &:last-child {
    border-bottom: none;
  }
  .hover {
    &:hover {
      cursor: pointer;
      color: var(--color-primary);
    }
  }
}

.custom-divider {
  margin: 0 10px;
}

.memo-wrapper {
  margin: -20px 40px 40px 40px;
  min-height: 40px;
}
.memo {
  background: #FFF7DC;
  border: 1px solid #EAD8A2;
  border-radius: 8px 0 8px 8px;
  padding: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    width: 18px;
    height: 18px;
    background: var(--color-white);
  }

  &:after {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    width: 18px;
    height: 18px;
    background: #EAD8A2;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
  }
}

.hover {
  &:hover {
    opacity: 1;
    cursor: pointer;
    color: var(--color-primary);
  }
}
</style>
