import { useFetch } from '@/api';
import { useSettings } from '@/composables/useSettings';
import { parseSeparatedNumber } from '@/utils/numberFormatter';
import { useNotifications } from '@/composables/useNotifications';

export const paymentsApi = () => {
  const generatePayment = async (payment) => {
    const { axiosWrapper, successHandler, t } = useFetch();
    const { numberFormat } = useSettings();
    const {
      data, isSuccess, isLoading, errorObject,
    } = await axiosWrapper({
      type: 'post',
      url: '/merchants/payments/generate-payment',
      params: {
        ...payment,
        baseAmount: parseSeparatedNumber(payment.baseAmount, numberFormat.value),
      },
    });

    if (isSuccess) {
      successHandler(t('merchantPayments.notification.generatePaymentSuccess'));
    }
    return {
      data, isSuccess, isLoading, errorObject,
    };
  };

  const sendPaymentRequest = async (payment) => {
    const { axiosWrapper, successHandler, t } = useFetch();
    const { numberFormat } = useSettings();
    const {
      data, isSuccess, isLoading, errorObject,
    } = await axiosWrapper({
      type: 'post',
      url: '/merchants/payments/send-payment-request',
      params: {
        ...payment,
        baseAmount: parseSeparatedNumber(payment.baseAmount, numberFormat.value),
      },
    });

    if (isSuccess) {
      successHandler(t('merchantPayments.notification.sendPaymentRequestSuccess'));
    }
    return {
      data, isSuccess, isLoading, errorObject,
    };
  };

  const createClient = async ({ email, name }) => {
    const { axiosWrapper } = useFetch();
    const { isSuccess, errorObject } = await axiosWrapper({
      type: 'post',
      url: 'merchants/clients',
      params: { fullName: name, email },
    });

    return { isSuccess, errorObject };
  };

  return {
    sendPaymentRequest,
    generatePayment,
    createClient,
  };
};

export const getPaymentHistoryById = async (paymentId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/merchants/payment/payment-history/${paymentId}`,
    params: {
      isFreshResponse: true,
    },
  });

  return { isSuccess, data };
};

export const getPaymentById = async (uuid) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/merchants/payments/uuid/${uuid}`,
    params: {
      isFreshResponse: true,
    },
  });

  return { isSuccess, data };
};

export const cancelPaymentById = async (paymentId) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'post',
    url: `merchants/payments/cancel/${paymentId}`,
  });

  return { isSuccess, data, successHandler };
};

export const journalPayment = async (paymentId, { note }) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data, error } = await axiosWrapper({
    type: 'post',
    url: `merchants/payments/journal/${paymentId}`,
    params: { note },
  });

  const { addNotification, t } = useNotifications();

  if (!isSuccess && error?.data?.code === 405 && error?.data?.message === 'MERCHANT_PAYMENT_QUOTA_EXHAUSTED') {
    addNotification({
      text: t('pay.notification.paymentQuotaExhaustedSystemError'),
      config: { color: 'warning', duration: 5000 },
    });
  }
  return { isSuccess, data, successHandler };
};

export const getJournalPayment = async (paymentId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `merchants/payments/journal/${paymentId}`,
    params: {
      isFreshResponse: true,
    },
    isErrorNotification: false,
  });

  return { isSuccess, data };
};
