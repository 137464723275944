import { reactive, ref, computed } from 'vue';

import { i18n } from '@/plugins/localization';
import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';
import { fullCoinsArray } from '@/common/data';

export const columns = [
  {
    name: 'paymentId',
    isShow: true,
    isPermanent: true,
    width: 60,
  },
  {
    name: 'uuid',
    isShow: false,
    width: 350,
    suppressSizeToFit: true,
  },
  {
    name: 'exchangeCurrency',
    type: TABLE_TYPES.CRYPTO,
    isShow: true,
    isPermanent: true,
    width: 150,
  },
  {
    name: 'address',
    isShow: true,
    isPermanent: true,
    width: 450,
    suppressSizeToFit: true,
  },
  {
    name: 'baseAmount',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.CURRENCY,
    width: 150,
  },
  {
    name: 'expectedAmount',
    isShow: false,
    type: TABLE_TYPES.CRYPTO_TEXT,
  },
  {
    name: 'amountReceived',
    isShow: false,
    type: TABLE_TYPES.CRYPTO_TEXT,
  },
  {
    name: 'marketRate',
    type: TABLE_TYPES.CURRENCY,
    isShow: false,
  },
  {
    name: 'memo',
    isShow: false,
  },
  {
    name: 'clientName',
    isShow: true,
    type: TABLE_TYPES.PERSON,
  },
  {
    name: 'clientEmail',
    isShow: false,
  },
  {
    name: 'createdAt',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.DATE_TIME,
  },
  {
    name: 'activatedAt',
    isShow: false,
    type: TABLE_TYPES.DATE_TIME,
  },
  {
    name: 'type',
    type: TABLE_TYPES.PAYMENT_TYPE,
    isShow: false,
  },
  {
    ...statusCellOptions,
  },
];

export const statuses = [
  { value: 'ALL' },
  { value: 'NEW' },
  { value: 'WAITING' },
  { value: 'UNCONFIRMED' },
  { value: 'COMPLETE' },
  { value: 'UNDERPAID' },
  { value: 'EXPIRED' },
  { value: 'CANCELLED' },
];

export const revokePeriods = computed(() => {
  const { t } = i18n.global;
  return [
    { text: `30 ${t('period.second', 30)}`, value: 'THIRTY_SECONDS' },
    { text: `1 ${t('period.minute', 1)}`, value: 'MINUTE' },
    { text: `5 ${t('period.minute', 5)}`, value: 'FIVE_MINUTES' },
    { text: `1 ${t('period.hour', 1)}`, value: 'HOUR', available: true },
    { text: `1 ${t('period.day', 1)}`, value: 'DAY', available: true },
    { text: `3 ${t('period.day', 3)}`, value: 'THREE_DAYS', available: true },
    { text: `1 ${t('period.week', 1)}`, value: 'WEEK', available: true },
    { text: `1 ${t('period.month', 1)}`, value: 'MONTH' },
  ];
});

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
  {
    field: 'coins',
    options: fullCoinsArray,
    type: 'COINS_SELECT',
    modelValue: ref([]),
  },
]);
