<template>
  <SmoothModal
    :model-value="isModalOpen"
    :has-header-divider="false"
    :has-body-paddings="false"
    :has-transition-height="false"
    width="690px"
    @update:model-value="onModalClose"
  >
    <template #title>
      {{ t('merchantPayments.paymentInfoModal.title') }}
    </template>
    <template #description>
      <DashboardModalDescription
        v-if="payment.uuid"
        :uuid="paymentInfo.uuid"
        :status="paymentInfo.status"
        :state="paymentInfo.state"
        @update="onUpdate"
      />
    </template>

    <template #default="{ height, hasTransitionHeight }">
      <TabsContainer
        v-model="currentTab"
        :options="tabs"
        :other-tab="{
          name: 'history',
          value: history.length,
        }"
        :is-loading="!isHistoryLoaded"
      >
        <MainInfo
          v-if="currentTab === tabs[0].value"
          :payment="paymentInfo"
          :is-loading="isLoading"
          :client-info="clientData"
        />
        <HistoryBlock
          v-if="currentTab === tabs[1].value"
          :data="history"
          :date-format="dateTimeFormat"
          :style="`${!hasTransitionHeight ? `max-height: ${height - 40}px` : ''};`"
        />
        <PaymentJournal
          v-if="currentTab === tabs[2].value"
          :id="paymentInfo?._id"
          :data="journalData"
          @complete="onJournalComplete"
        />
      </TabsContainer>

      <div v-if="isBottomBlockVisible" class="bottom-block d-flex align-items-center">
        <template v-if="['WAITING', 'NEW', 'EXPIRED'].includes(paymentStatus) && paymentInfo.isExpiredPermanent === false">
          <FButton
            v-if="['NEW', 'EXPIRED'].includes(paymentStatus) "
            type="danger"
            :loading="isCancelLoading"
            style="margin-right: 44px;"
            size="small"
            @click="onCancel"
          >
            <AppIcon
              name="ban-block-slash"
              fill="var(--color-white)"
              size="16px"
            />
            <AppText class="font-medium">
              {{ t('merchantPayments.paymentInfoModal.button.cancel') }}
            </AppText>
          </FButton>

          <div v-if="['WAITING'].includes(paymentStatus)" class="info">
            <AppIcon
              name="info-circle-big"
              size="12px"
              style="margin-right: 8px"
              :opacity="0.4"
              is-img-tag
            />
            <AppText :line-height="1.35" opacity="0.4">
              {{ t('merchantPayments.paymentInfoModal.label.cantBeCancelled') }}
            </AppText>
          </div>
        </template>
      </div>
    </template>
  </SmoothModal>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import DashboardModalDescription from '@/components/DashboardModalDescription.vue';
import HistoryBlock from '@/components/HistoryBlock.vue';
import SmoothModal from '@/components/SmoothModal';
import TabsContainer from '@/components/Containers/TabsContainer.vue';

import { emitter } from '@/composables/useBus';
import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';
import { useProfile } from '@/composables/useProfile';
import { useSocket } from '@/composables/useSocket';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

import {
  cancelPaymentById,
  getJournalPayment,
  getPaymentById,
  getPaymentHistoryById,
} from '../api';

import PaymentJournal from './PaymentJournal.vue';
import MainInfo from './MainInfo';

const props = defineProps({
  payment: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const emit = defineEmits(['update', 'update-status']);

const isModalOpen = defineModel({ type: Boolean, default: false });
const { t } = i18n.global;

const paymentData = ref({});
const paymentInfo = computed(() => (Object.keys(paymentData.value).length ? paymentData.value : props.payment));
const journalData = ref({});

const tabs = computed(() => {
  const isJournalDisabled = (() => {
    if (['EXPIRED', 'CANCELLED'].includes(paymentInfo.value.status)) {
      return false;
    }

    if (paymentInfo.value.status === 'COMPLETE' && (props.payment.journalNote?.length || journalData.value?.note?.length)) {
      return false;
    }
    return true;
  })();

  return journalData.value && [
    { text: t('merchantPayments.paymentInfoModal.tab.overview'), value: 'overview' },
    { text: t('merchantPayments.paymentInfoModal.tab.history'), value: 'history' },
    {
      text: t('merchantPayments.paymentInfoModal.tab.journal'),
      value: 'journal',
      disabled: isJournalDisabled,
      tooltipMessage: t('merchantPayments.paymentInfoModal.label.journalAvailable'),
    },
  ];
});
const currentTab = ref(tabs.value[0].value);

const history = ref([]);
watch(isModalOpen, async (isOpen) => {
  if (isOpen) {
    history.value = [];

    getPaymentData();
  }

  if (!isOpen) {
    history.value = [];
    paymentData.value = {};
    currentTab.value = tabs.value[0].value;
    isCancelLoading.value = false;
    journalData.value = {};
  }
});

const clientData = computed(() => ({
  name: props.payment.clientName,
  email: props.payment.clientEmail,
  shortId: props.payment.clientShortId,
}));

const isHistoryLoaded = ref(false);

const isLoading = ref(false);
const getPaymentData = async (shouldGetJournal = false) => {
  if (props.payment.uuid) {
    isLoading.value = true;
    await getPaymentById(props.payment.uuid).then((res) => {
      paymentData.value = res.data;
      isLoading.value = false;
      setTimeout(() => {
        if (currentTab.value !== tabs.value[1].value) {
          emitter.emit('calculate-height');
        }
      }, 0);
    });

    getPaymentHistoryById(props.payment.id).then((res) => {
      history.value = res.data;
      isHistoryLoaded.value = true;
    });

    if (props.payment.journalNote || shouldGetJournal) {
      getJournalPayment(props.payment.id).then((res) => {
        if (res.isSuccess) {
          journalData.value = {
            updatedAt: res.data.updatedAt,
            note: res.data.note,
          };
          const journalIndex = history.value.findIndex((el) => el.type === 'JOURNAL');
          if (journalIndex > -1) {
            history.value[journalIndex].journalNote = res.data.note;
          }
        } else {
          journalData.value = {};
        }
      });
    }
  }
};

const onUpdate = () => {
  emit('update');
  getPaymentData();
};

const onJournalComplete = () => {
  emit('update');
  getPaymentData(true);
};

const isCancelLoading = ref(false);
const onCancel = async () => {
  isCancelLoading.value = true;
  const { isSuccess, successHandler } = await cancelPaymentById(props.payment.id);
  if (isSuccess) {
    successHandler(t('merchantPayments.notification.paymentCancelledSuccess'));
    emit('update');
    isModalOpen.value = false;
  } else {
    const { addNotification } = useNotifications();
    addNotification({ text: t('merchantPayments.notification.paymentCancelledFail'), config: { color: 'error' } });
    getPaymentData();
  }
  isCancelLoading.value = false;
};

const { socket, addSocketListener } = useSocket();

watch(computed(() => props.payment.id), () => {
  if (props.payment.id) {
    socket.emit('room', props.payment.id);
  }
});

addSocketListener('status-update', (message) => {
  if (message.paymentId === props.payment.id) {
    emit('update-status', message);
    onUpdate();
  }
});

const { dateTimeFormat } = useProfile();

const onModalClose = () => {
  isModalOpen.value = false;
  isHistoryLoaded.value = false;
};

const paymentStatus = computed(() => paymentData.value?.status || props.payment?.status);

const isBottomBlockVisible = computed(() => {
  if (currentTab.value === tabs.value[0].value) {
    if (['WAITING', 'NEW', 'EXPIRED'].includes(paymentStatus.value) && paymentInfo.value.isExpiredPermanent === false) {
      return true;
    }
  }

  return false;
});

useKeyboardEvent(keys.BACKQUOTE, () => { isModalOpen.value = false; });
</script>

<style lang="scss" scoped>
.bottom-block {
  margin: 0 40px 40px;
  height: 44px;

  .info {
    background: var(--color-black-003);
    border-radius: 4px;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
  }
}
</style>
