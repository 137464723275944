<template>
  <div>
    <div class="payment-data">
      <AppText variant="div" mb="10px">
        {{ t('merchantPayments.paymentInfoModal.label.publicLink') }}
      </AppText>
      <TooltipCopy class="payment-link" :text="linkToCopy">
        <div class="d-flex align-items-center">
          <AppIcon
            name="globe-world"
            style="margin-right: 8px"
            :opacity="0.4"
            is-img-tag
            size="16px"
          />
          <AppText size="15px">
            {{ paymentLink }}
          </AppText>
        </div>
      </TooltipCopy>

      <PaymentAmount :payment="payment" :is-loading="isLoading" />
    </div>
    <div
      v-if="payment.address"
      class="deposit-address d-flex align-items-center"
    >
      <AppText color="#949494" mr="20px">
        {{ t('merchantPayments.paymentInfoModal.label.depositAddress') }}
      </AppText>
      <TooltipCopy :text="payment.address">
        <div class="d-flex">
          <AppText>
            {{ truncateLongString(payment.address) }}
          </AppText>
        </div>
      </TooltipCopy>
    </div>

    <PaymentDetails v-bind="$props" :key="clientInfo?.email" />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';

import { truncateLongString } from '@/utils/functions';
import { useApp } from '@/composables/useApp';

import PaymentDetails from './PaymentDetails.vue';
import PaymentAmount from './PaymentAmount.vue';

export default defineComponent({
  components: {
    TooltipCopy,
    PaymentAmount,
    PaymentDetails,
  },
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
    clientInfo: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { IS_ONION, BASE_URL, truncatedShortOnionUrl } = useApp();

    const paymentLinkEnd = computed(() => `payment/${props.payment.uuid}`);
    const paymentLink = computed(() => {
      const URL = IS_ONION ? truncatedShortOnionUrl : BASE_URL;

      return `${URL}/${paymentLinkEnd.value}`;
    });

    const linkToCopy = computed(() => {
      if (IS_ONION) {
        return `http://${BASE_URL}/${paymentLinkEnd.value}`;
      }
      return `https://${BASE_URL}/${paymentLinkEnd.value}`;
    });

    return {
      paymentLink,
      BASE_URL,
      linkToCopy,
      truncateLongString,
    };
  },
});
</script>

<style scoped lang="scss">
.payment-data {
  border-top: 1px solid var(--color-black-01);
  padding: 30px 40px;
  background: #F7F7F7;
}

.payment-link {
  display: flex !important;
  align-items: center;
  background: #fff;
  border: 1px solid var(--color-black-01);
  border-radius: 4px;
  padding: 0 18px;
  height: 48px;

  :deep(.text) {
    flex: 1 1 auto;
  }
}

.deposit-address {
  padding: 24px 40px;
  border-top: 1px solid var(--color-black-01);
  background: #F7F7F7;
}

</style>
